<template>
    <div class="container">
        <div class="success-tips-panel">
            <div class="success-tips-inner">
                <span class="icon">
                    <img :src="$img.success_ico" />
                </span>
                <div class="success-txt">
                    <p>您所填写的资料已经顺利提交，审核结果将会以短信方式通知您，请耐心等待。</p>
                    <el-row>
                        <el-button @click="back">返回</el-button>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {};
    },
    methods: {
        back() {
            this.$router.push('/user/info');
        }
    }
};
</script>
<style lang="less">
@import "../../../styles/signup.less";
.back-task-detail:hover {
    background: #4564b3 !important;
}
</style>
<style lang="less" scoped>
.success-tips-panel {
    margin: 0;
    padding: 70px 0 340px 0;
    text-align: center;
    background: white;

    .success-txt {
        > p {
            margin: 0 auto;
        }
    }
    .icon {
        float: initial;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .success-txt {
        margin-left: -20px;
    }
}
.success-tips-inner {
    margin: 20px;
    overflow: hidden;
    background: white;
}
</style>